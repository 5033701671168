import React from "react";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import TextEditor from "../../components/texteditor/Texteditor";
import { baseUrl } from "../../network/ApiUrl";
import { contentpost, editpost, homecontent } from "../../network/Network";
import { onInputChangeHandler , onDirectChangeHandler } from "../../utils/helpers";
import AddService from "./AddService";
import EditService from "./EditService";

function Home() {
  const [content, setContent] = useState([]);
  const [homeServices, setHomeServices] = useState([]);
  const [singleForm, setSingleFrom] = useState(null);
  const [contents, setContents] = useState([]);
  const [loader, setLoader] = useState(false);
  const [add, setAdd] = useState(false);
  const [newFile, setNewFile] = useState();
  const [fileupload, setFileupload] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [heading, setHeading] = useState();
  const [headings, setHeadings] = useState();
  const [bannerImage, setBannerImage] = useState();
  const [bannerImages, setBannerImages] = useState();
  const [subHeading, setSubHeading] = useState();
  const [smallHeading, setSmallHeading] = useState();
  const [aboutContent, setAboutContent] = useState();
  const [successMessages, setSuccess] = useState();
  const [url, setUrl] = useState();
  const [editForm , setEditForm] = useState({
    heading : '',
    bannerImage : null,
    url : '',
    content : ''
  });
  const [form, setForm] = useState({
    bannerImage: null,
    bannerImages:null,
    heading: "",
    headings:"",
    content: "",
    contents:"",
    subHeading: "",
    aboutContent: "",
    smallHeading: "",
    url:"",
  });

  const onClickEditHandler = (id) => {
    let x = homeServices?.find(val=>val?._id === id);
    console.log(x);
    setEditForm((preVal)=>{
      return {
        bannerImageUrl : x?.bannerImage,
        heading : x?.heading,
        bannerImage : null,
        url : x?.url,
        content : x?.content
      }
    })
  }

  // Home Edit Api
  const EditcontentData = (e, _id) => {
    e.preventDefault();
    var data = new FormData();
    data.append("heading", editForm?.heading);
    data.append("bannerImage", editForm?.bannerImage);
    data.append("content", editForm?.content);
    data.append("url", editForm?.url);
    console.log(data);
    editpost(data, selectedId)
      .then((res) => {
        console.log(res);
        toast.success("Successfully send");
        // setHomeServices(true);
        setLoading(false);
        setHeadings("");
        setBannerImages("");
        setContents("");
        setUrl("");
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err?.response?.data?.errors);
        console.log(err);
      });
  };
  // =======================================

  // Home Post Api
  const PostcontentData = (e) => {
    e.preventDefault();
    var data = new FormData();
    data.append("heading", heading);
    data.append("bannerImage", bannerImage);
    data.append("subHeading", subHeading);
    data.append("smallHeading", smallHeading);
    data.append("aboutContent", aboutContent);
    console.log(data);
    contentpost(data)
      .then((res) => {
        console.log(res);
        setSuccess(res?.data)
        toast.success("Successfully send");
      })
      .catch((err) => {
        toast.error(err?.response?.data?.errors);
        console.log(err);
      });
  };

  console.log(successMessages,"success")


  // useEffect(()=>{
  // if(successMessages?.message === 'News Added SuccessFully'){
  //   window.location.reload();
  // }
  // },[successMessages?.message])
  // ===================================================

  // Home Get Api
  useEffect(() => {
    homecontent()
      .then((res) => {
        console.log(res, "tttttttttt");
        setContent(res?.data?.data);
        setForm((preval) => ({
          ...preval,
       
          heading: res?.data?.data?.heading,
          headings: res?.data?.data?.headings,     
          subHeading: res?.data?.data?.subHeading,
          smallHeading: res?.data?.data?.smallHeading,
          aboutContent: res?.data?.data?.aboutContent,
        }));

        setHeading(res?.data?.data?.heading);
        setHeadings(res?.data?.data?.headings);
        setSubHeading(res?.data?.data?.subHeading);
        setSmallHeading(res?.data?.data?.smallHeading);
        setAboutContent(res?.data?.data?.aboutContent);
      
        setHomeServices(res?.data?.data?.homeServices);
       
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // =========================

  // Image Handler
  const handleImageUpload = (e) => {
    setBannerImages(e.target.files[0]);
    setBannerImage(e.target.files[0]);
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setNewFile(file);
      console.log("file", file);
      setFileupload(reader.result);
    };
    reader.readAsDataURL(file);
    // setUploadLoading(true);
  };
  // ======================================


  return (
    <>
      <section className="home-sec">
        <div className="container">
          <div className="home-head text-center">
            <h2>ADD HOME CONTENT</h2>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="home-all">
                <div className="home-head">
                  <h3>Heading</h3>
                  <input
                    type="text"
                    value={heading}
                    onChange={(e) => setHeading(e.target.value)}
                  ></input>
                </div>
                <div className="home-head">
                  <h3>Banner Image</h3>
                  <img src={baseUrl + content?.bannerImage} width={"30%"} />
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    multiple={false}
                  />
                </div>
                <div className="home-head">
                  <h3>Sub Heading</h3>
                  <input
                    type="text"
                    value={subHeading}
                    onChange={(e) => setSubHeading(e.target.value)}
                  ></input>
                </div>
                <div className="home-head">
                  <h3>Small Heading</h3>
                  <input
                    type="text"
                    value={smallHeading}
                    onChange={(e) => setSmallHeading(e.target.value)}
                  ></input>
                </div>
                <div className="home-head">
                  <h3>About Content</h3>
                  <div class="form-group">
                    <TextEditor
                      placeholder={"Enter"}
                      content={aboutContent}
                      setContent={setAboutContent}
                    />
                  </div>
                </div>
                <div className="submit-btn">
                   <button onClick={PostcontentData}>
                    {loader ? (
                      <i className={"fa fa-spin fa-spinner"}></i>
                    ) : (
                      "Save Changes"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Home Content box section  */}
      <section className="Home-content-service-sec">
        <div className="Container">
          <div className="home-head home-service-head text-center">
            <h2>HOME SERVICE CONTENT</h2>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="all-table">
                <table className="sevice-table table-team ">
                  <tbody>
                    {homeServices.map((data, index) => {
                      return (
                        <tr>
                          <td>
                            <h3 key={data?.id}>{index + 1} </h3>
                          </td>
                          <td>
                            <img
                              className="img-fluid"
                              src={baseUrl + data?.bannerImage}
                            ></img>
                          </td>
                          <td>
                            <h3>{data?.heading}</h3>
                          </td>
                          <td>
                            <p>{data?.content}</p>
                          </td>
                          <td>
                            <h5>{data?.url}</h5>
                          </td>
                          <td>
                            <button
                              aria-hidden="true"
                              data-toggle="modal"
                              data-target="#exampleModal"
                              type={"button"}
                              onClick={() => {
                                setSelectedId(data?._id);
                                onClickEditHandler(data?._id);
                              }}
                            >
                              <i className="fa fa-pencil-square-o"></i>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of Home Content section  */}

      {/* Home Modal */}
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title " id="exampleModalLabel">
               ADD SERVICES
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="team-img">
                <div className="team-head">
                <img src={baseUrl + editForm?.bannerImageUrl} width={"30%"} />
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    multiple={false}
                  />
                </div>
              </div>
              <div className="team-designation">
                <div className="team-head">
                  <h3>Heading</h3>
                  <input
                    type="text" name="heading"
                    value={editForm?.heading}
                    onChange={(e) => onInputChangeHandler(e,setEditForm)}
                  ></input>

                  
                </div>
              </div>

              <div className="team-designation">
                <div className="team-head">
                  <h3>Content</h3>
                  {/* <input
                    type="text" name="content"
                    value={editForm?.content}
                    onChange={(e) => onInputChangeHandler(e,setEditForm)}
                  ></input> */}
                      <TextEditor
                      placeholder={"Enter"}
                      content={editForm?.content}
                      setContent={(value)=> onDirectChangeHandler('content',value,setEditForm)}
                    />
                  
                </div>
              </div>

              <div className="team-description">
                <div className="team-head">
                  <h3>url</h3>
                  <input
                    type="text" name="url"
                    value={editForm?.url}
                    onChange={(e) => onInputChangeHandler(e,setEditForm)}
                  ></input>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={(e) => EditcontentData(e, content?._id)}
                disabled={loading}
                data-dismiss="modal"
              >
                {loading ? "Loading.." : "Save changes"}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* ====================== */}
    </>
  );
}
export default Home;
