import React, { useState, useEffect } from "react";
import DashboardHeader from "../../components/DashboardHeader";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";

import all_orders from "../../constants/orders";
import { calculateRange, sliceData } from "../../utils/table-pagination";
import { Modal, Button } from "react-bootstrap";
import { SHOW, SINGLE_TEAM_MEMBER, TEAM_MEMBER } from "../../network/Endpoint";
import { ApiUrl, baseUrl } from "../../network/ApiUrl";

import "../styles.css";
import DoneIcon from "../../assets/icons/done.svg";
import CancelIcon from "../../assets/icons/cancel.svg";
import RefundedIcon from "../../assets/icons/refunded.svg";
import { toast } from "react-toastify";
import { PostTeams, teamdelete, teamupdate } from "../../network/Network";
import { Link } from "react-router-dom";
import { USER_TOKEN } from "../../Redux/Types";
import { useSelector } from "react-redux";
import TextEditor from "../../components/texteditor/Texteditor";

function Teams() {
  const [show, setShow] = useState(false);
  const [teamMember, setteamMember] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [spinLoad, setSpinLoad] = useState(false);
  // const [convertedText, setConvertedText] = useState("Some default content");
  const [selectedId, setSelectedId] = useState();
  const [selectedData, setSelectedData] = useState();

  const [name, setName] = useState();
  console.log("test", selectedData?.name);
  const TeamData = useSelector((state) => state.AuthReducer.allteam);
  const [title, setTitle] = useState();
  const [state, setState] = useState();
  const [image, setImage] = useState(null);
  // const [loading, setLoading] = useState(false);
  const [fileupload, setFileupload] = useState();
  const [newFile, setNewFile] = useState();
  const [data, setData] = useState([]);
  const [imageSent, setImageSent] = useState(null);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [description, setDescription] = useState();
  const [editstate, setEditState] = useState(false);

  console.log(selectedData, "kjkjjjjjj");
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    image: null,
    imageSent:null,
  });

  
  // Image Handler
  const handleImageUpload = (e) => {
    setImageSent(e.target.files[0]);
    setImage(e.target.files[0]);
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setNewFile(file);
      console.log("file", file);
      setFileupload(reader.result);
    };
    reader.readAsDataURL(file);
    // setUploadLoading(true);
  };
  // =======================================

  // Delete Team Api 
  const DeleteDepartHandler = (e, id) => {
    e.preventDefault();
    // setRemoveLoading(true);
    teamdelete(id)
      .then((res) => {
        console.log(res);
        setteamMember((prev) => {
          return [...prev.filter((val) => val?._id !== id)];
        });
        // toast.success(res?.data?.message);
        // teamMember?.splice(index, 1);
        // setteamMember(res?.data?.response?.data?.data);
        // setRemoveLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setRemoveLoading(false);
      });
  };
  // ======================================

  setTimeout(() => {
    console.log("sssssssssssssssssssssssssssssssssssssss", newFile);
  }, 5000);

    // Team Post Api
  const PostTeamData = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("name", name);
    formData.append("title", title);
    formData.append("image", imageSent);
    formData.append("description", description);

    // var data = new FormData();
    // data.append("name", name);
    // data.append("title", title);
    // data.append("image", imageSent);
    // data.append("description", description);
    PostTeams(formData)
      .then((res) => {
        console.log(res);
        handleClose();
        toast.success("Successfully send");
      })
      .catch((err) => {
        toast.error(err?.response?.data?.errors);
        console.log(err);
      });
  };
  const SchooliesApi = async () => {
    try {
      const { data } = await axios.get(SINGLE_TEAM_MEMBER);
      console.log(data.response.data);
      setState(data.response.data);
    } catch (error) {
      console.log(error);
    }
  };
  // =======================================

    // Team Get Api
  useEffect(() => {
    axios.get(`${ApiUrl + TEAM_MEMBER}`).then((res) => {
      setteamMember(res?.data?.data);
      setForm((preval) => ({
        ...preval,
      }));
      console.log(res?.data?.data, "tttttttttttt");
      // console.log(teamMember?.name, "tttttttttttt");
    });
    SchooliesApi();
  }, [editstate]);

  console.log(teamMember,"get data")
  // ==========================

  // Team Update Api
  const Updateteammember = async (e) => {
    setLoading(true);
    e.preventDefault();
    setLoading(true);
    let data = await new FormData();
    data.append("name", name);
    data.append("title", title);
    data.append("image", imageSent);
    data.append("description", description);
    teamupdate(data, selectedId)
      .then((res) => {
        toast.success("Successfully send");
        setEditState(true);
        setLoading(false);
        setName("");
        setTitle("");
        setImageSent("");
        setDescription("");
        setSelectedId("");
      })
      .catch((err) => {
        toast.error(err?.response?.data?.errors);
        console.log(err);
        setLoading(false);
        setName("");
        setTitle("");
        setImageSent("");
        setDescription("");
        console.log(err);
      });
  };
  // =========================================

  // Edit Handler
  const EditStateHandler = (e, item) => {
    e.preventDefault();
    setName(item?.name);
    setTitle(item?.title);
    setImage(item?.image);
    setDescription(item?.description);
  };
  // =============================

  return (
    <>
    {/* All stuff */}
      <div className="container-fluid">
        <div className="row">
          {/* <DashboardHeader btnText="New Order" /> */}

          <div className="col-md-12">
            <div className="blog-btn add-new-modal">
              <Button onClick={handleShow}>Add New</Button>
            </div>
            <Modal show={show} onHide={handleClose}>
              <Modal.Body>
                <div className="team-img">
                  <div className="team-head">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageUpload}
                      multiple={false}
                    />
                    <h3>Upload img</h3>
                  </div>
                </div>
                <div className="team-designation">
                  <div className="team-head">
                    <h3>Name</h3>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>

                <div className="team-designation">
                  <div className="team-head">
                    <h3>Title</h3>
                    <input
                      type="text"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    ></input>
                  </div>
                </div>

                <div className="team-description">
                  <div className="team-head">
                    <h3>Description</h3>
                    <TextEditor
                      placeholder={"Enter"}
                      content={description}
                      setContent={setDescription}
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={PostTeamData}>
                  Save
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>

        <div className="row">
          <table className="table-team ">
            <tbody>
              {teamMember.map((data, index) => {
                return (
                  <tr>
                    <td>
                      {" "}
                      <h3 key={data?.id}>{index + 1} </h3>
                    </td>
                    <td>
                      {" "}
                      <img
                        className="img-fluid"
                        src={baseUrl + data?.image}
                      ></img>
                    </td>
                    <td>
                      {" "}
                      <h3>{data?.title}</h3>
                    </td>
                    <td>
                      {" "}
                      <h3>{data?.name}</h3>
                    </td>
                    <td>
                      {" "}
                      <i
                        class="fa fa-trash-o"
                        aria-hidden="true"
                        onClick={(e) => DeleteDepartHandler(e, data?._id)}
                      ></i>
                      <i
                        class="fa fa-pencil-square-o"
                        aria-hidden="true"
                        data-toggle="modal"
                        data-target="#exampleModal"
                        onClick={(e) => {
                          EditStateHandler(e, data);
                          setSelectedId(data?._id);
                        }}
                      ></i>
                      <Link to={`/team-detail/${data?._id}`}>
                        <i class="fa fa-eye" aria-hidden="true"></i>
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

        {/* Team Modal   */}
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Edit Your Team Memeber Info
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="team-img">
                <div className="team-head">
                  <img src={baseUrl + teamMember?.find(val=>val?._id === selectedId )?.image} width={"30%"} />

                  
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    multiple={false}
                  />
                </div>
              </div>
              <div className="team-designation">
                <div className="team-head">
                  <h3>Name</h3>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  ></input>
                </div>
              </div>

              <div className="team-designation">
                <div className="team-head">
                  <h3>Title</h3>
                  <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  ></input>
                </div>
              </div>

              <div className="team-description">
                <div className="team-head">
                  <h3>Description</h3>
                  {/* <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea> */}
                  <TextEditor
                    placeholder={"Enter"}
                    content={description}
                    setContent={setDescription}
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={(e) => Updateteammember(e)
                }
                data-dismiss="modal"
              >
                {loading ? "Loading.." : "Save changes"}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* ========================= */}

      {/* ==================================== */}
    </>
  );
}

export default Teams;
