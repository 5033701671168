import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { login } from "../../Redux/actions/AuthAction";
import { toast } from "react-toastify";


const Login =()=>{
  const dispatch = useDispatch();
  const token = useSelector((state) => state.AuthReducer.token);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState("");
  const SignInHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!email && !password) {
      toast.error("Invalid username or password");
      setLoading(false);
      return;
    } 
    else {
      let data = {
        email: email,
        password: password,
        role: "admin",
      };
      console.log(data);
      setTimeout(async () => {
        setLoading(false);
        let x = await dispatch(login(data));
        console.log("response login", x);

        if (x) {
          window.location.href = "/";
        }
      }, 600);
    }
  };
    return(
        <>
        <section className="singnin">
        <div className="container-fluid">
          <div className="row">
           
            <div className="col-lg-12 p-0">
              <div className="sherwoodFrom">
                <div className="form-Flex">
                  <div className="logoDv">
                    <figure>
                    
                    </figure>
                  </div>
                 
                  <h2 className="title pb-3 blueish">Sign In</h2>
                  <div className="textDv pb-2">
                    <h4 className="subtext authText">
                      Welcome to Sherwood
                    </h4>
                    {/* <h4 className="bld authText-sub">
                      <strong>We you Help to move your deliveries</strong>
                    </h4> */}
                  </div>
                  {/* <div className="memberLink pb-3  authText">
                    <p>
                      Not a Member?
                      <Link to="/signup" className="blueish">
                        Sign Up
                      </Link>
                    </p>
                  </div> */}
                  <form>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                       
                        required
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <button
                        className="form-control btn"
                        onClick={(e) => {
                          SignInHandler(e);
                        }}
                        disabled={loading}
                      >
                        {loading ? "Loading..." : "Sign In Your Account"}
                      </button>
                    </div>
                    {/* <div className="optionDv">
                      <div className="rememberDv">
                        <div className="form-check">
                          <input
                            className="form-check-input position-static"
                            type="checkbox"
                            id="blankCheckbox"
                            value="option1"
                            aria-label="..."
                          />
                          <label for="blankCheckbox">Remember Me</label>
                        </div>
                      </div>
                      <div className="forgotDv">
                        <Link to="/forgetpassword" className="blueish">
                          Forgot Password?
                        </Link>
                      </div>
                    </div> */}
                    {/* <div className="privacyDv">
                      <h5>
                        By Creating Account you are agree to our{" "}
                        <a className="blueish" href="">
                          Terms & Conditions
                        </a>
                      </h5>
                    </div> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
        
        </>
    )
}

export default Login;