export const onInputChangeHandler = (e,setState) => {
    let { name , value } = e.target;
    setState((preVal)=>{
        return {
            ...preVal,
            [name] : value
        }
    })
}

export const onDirectChangeHandler = (name,value,setState) => {
    setState((preVal)=>{
        return {
            ...preVal,
            [name] : value
        }
    })
}

export const onImageChangeHandler = (e,setState) => {
    // setBannerImage(e.target.files[0]);
    let { name } = e.target;
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
        // setNewFile(file);
        // console.log("file", file);
        setState((preVal)=>{
            return {
                ...preVal,
                [name] : file
            }
        })
        // setFileupload(reader.result);
    };
    reader.readAsDataURL(file);
    // setUploadLoading(true);
};

export const toFormData = (columns = [],form) => {
    let x = new FormData();
    columns.map( (val) => {
        x.append(val, form[val]);
    })
    return x;
}