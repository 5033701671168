import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { youripdelete, youripget } from "../../network/Network";

function Submitip() {
  const [name, setName] = useState("");
  const [office, setOffice] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [cell, setCell] = useState("");
  const [company, setCompany] = useState("");
  const [time, setTime] = useState("");
  const [address, setAddress] = useState("");
  const [contacttime, setContacttime] = useState("");
  const [loading, setLoading] = useState(false);
  const [spinLoad, setSpinLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [settingData, setSettingData] = useState([]);
  const [pageCount, setPageCount] = useState();
  let limit = 10;

  useEffect(() => {
    youripget()
      .then((res) => {
        console.log(res);
        setSettingData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // =============================

  // Contact Delete Api
  const DeleteYourHandler = (e, id) => {
    e.preventDefault();
    // setRemoveLoading(true);
    youripdelete(id)
      .then((res) => {
        console.log(res);
        setSettingData((prev) => {
          return [...prev.filter((val) => val?._id !== id)];
        });
        // toast.success(res?.data?.message);
        // teamMember?.splice(index, 1);
        // setteamMember(res?.data?.response?.data?.data);
        // setRemoveLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setRemoveLoading(false);
      });
  };
  // =======================================
  return (
    <>
      {/* All stuff */}
      <section className="Contact-sec">
        <div className="Conatct-head">
          <h1>All Sumbit Ip Queries</h1>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="contact-name">
              <table className="contact-table contac-table">
                <thead className="th-submit">
                  <th>Srno.</th>
                  <th>Name</th>
                  <th>Company</th>
                  <th>Email</th>
                  <th>Office Number</th>
                  <th>Cell Number</th>
                  <th>Address</th>
                  <th>Best Time To Contact</th>
                 
                  <th>Del</th>
                </thead>
                {settingData.map((data, index) => {
                  console.log(data);
                  return (
                    <tr>
                      <td>
                        <h3 key={data?._id}>{index + 1} </h3>
                      </td>
                      <td>
                        <h3>{data?.name}</h3>
                      </td>
                      <td>
                        <h3>{data?.company}</h3>
                      </td>
                      <td>
                        <h3>{data?.email}</h3>
                      </td>
                      <td>
                        <h3>{data?.office}</h3>
                      </td>
                      <td>
                        <h3>{data?.cell}</h3>
                      </td>
                      <td>
                        <h3>{data?.address}</h3>
                      </td>
                      <td>
                        <h3>{moment(data?.time).format(" MMM Do YYYY")}</h3>
                      </td>
                      <td>
                        <i
                          class="fa fa-trash-o"
                          aria-hidden="true"
                          onClick={(e) => DeleteYourHandler(e, data?._id)}
                        ></i>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </section>
      {/* =============================== */}
    </>
  );
}

export default Submitip;
