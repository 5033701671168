import React, { useEffect, useState } from "react";
import DashboardHeader from "../../components/DashboardHeader";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";

import all_orders from "../../constants/orders";
import { calculateRange, sliceData } from "../../utils/table-pagination";
import { Modal, Button } from "react-bootstrap";
import {
  CONTACT,
  GET_ALL,
  SHOW,
  SINGLE_TEAM_MEMBER,
  TEAM_MEMBER,
} from "../../network/Endpoint";
import { ApiUrl, baseUrl } from "../../network/ApiUrl";

import "../styles.css";
import DoneIcon from "../../assets/icons/done.svg";
import CancelIcon from "../../assets/icons/cancel.svg";
import RefundedIcon from "../../assets/icons/refunded.svg";
import { toast } from "react-toastify";
import {
  contactdelete,
  contactid,
  contactshow,
  PostTeams,
  teamdelete,
} from "../../network/Network";
import { Link } from "react-router-dom";
import { USER_TOKEN } from "../../Redux/Types";
import moment from "moment";

function Contac() {
  const [con, setCon] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [company, setCompany] = useState("");
  const [position, setPosition] = useState("");
  const [purpose, setPurpose] = useState("");
  const [contacttime, setContacttime] = useState("");
  const [loading, setLoading] = useState(false);
  const [settingData, setSettingData] = useState([]);
  const [state, setState] = useState();
  const [spinLoad, setSpinLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  let limit = 10;

  // Contact Get Api
  useEffect(() => {
    setSpinLoad(true);
    contactid()
      .then((res) => {
        console.log(res);
        setCon(res?.data?.data);
        // dispatch(setblogstoress(res?.data?.response?.data));
        setSpinLoad(false);
        const total = res?.data?.response?.data?.total;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
        setSpinLoad(false);
      });
  }, [limit, currentPage]);
  // =============================

  // Contact Delete Api
  const DeletecontactHandler = (e, id) => {
    e.preventDefault();
    // setRemoveLoading(true);
    contactdelete(id)
      .then((res) => {
        console.log(res);
        setCon((prev) => {
          return [...prev.filter((val) => val?._id !== id)];
        });
        // toast.success(res?.data?.message);
        // teamMember?.splice(index, 1);
        // setteamMember(res?.data?.response?.data?.data);
        // setRemoveLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setRemoveLoading(false);
      });
  };
  // =======================================
  return (
    <>
      {/* All stuff */}
      <section className="Contact-sec">
        <div className="Conatct-head">
          <h1>All Contact Queries</h1>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="contact-name">
             
              <table className="contact-table contac-table con-table">
              <thead>
                <th>Srno.</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Company</th>
                <th>Position</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Purpose Of Contact</th>
                <th>Best Time To Contact</th>
                <th>Your Message</th>
              </thead>
                {con.map((data, index) => {
                  console.log(data);
                  return (
                    <tr>
                      <td>
                        <h3 key={data?._id}>{index + 1} </h3>
                      </td>
                      <td>
                        <h3>{data?.first_name}</h3>
                      </td>
                      <td>
                        <h3>{data?.last_name}</h3>
                      </td>
                      <td>
                        <h3>{data?.company}</h3>
                      </td>
                      <td>
                        <h3>{data?.position}</h3>
                      </td>
                      <td>
                        <h3>{data?.email}</h3>
                      </td>
                      <td>
                        <h3>{data?.phone_no}</h3>
                      </td>
                      <td>
                        <h3>{data?.purpose_of_contact}</h3>
                      </td>
                      <td>
                        <h3>
                        {moment(data?.contact_time).format(" MMM Do YYYY")}
                         </h3>
                      </td>
                      <td className="for-small-contentssss">
                        <h3>{data?.message}</h3>
                      </td>
                      <td>
                        <i
                          class="fa fa-trash-o"
                          aria-hidden="true"
                          onClick={(e) => DeletecontactHandler(e, data?._id)}
                        ></i>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </section>
      {/* =============================== */}
    </>
  );
}

export default Contac;
