import { Editor, EditorState } from "draft-js";
import JoditEditor from "jodit-react";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { baseUrl } from "../../network/ApiUrl";
import { affilationget, affilationpost } from "../../network/Network";
import {
  onImageChangeHandler,
  onInputChangeHandler,
} from "../../utils/helpers";
import TextEditor from "../../components/texteditor/Texteditor";

function Affilation() {
  const [bannerImage, setBannerImage] = useState(null);
  const [newFile, setNewFile] = useState();
  const [fileupload, setFileupload] = useState();
  const [content, setContent] = useState("");
  const [loader, setLoader] = useState(false);
  const [affilate, setAffilate] = useState([]);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [form, setForm] = useState({
    bannerImage: null,
    content: "",
  });

    // Image Handler
  const handleImageUpload = (e) => {
    setBannerImage(e.target.files[0]);
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setNewFile(file);
      console.log("file", file);
      setFileupload(reader.result);
    };
    reader.readAsDataURL(file);
    // setUploadLoading(true);
  };
  // ===================================

   // Affilation Post Api
  const Affilationdata = (e) => {
    e.preventDefault();
    var data = new FormData();
    data.append("bannerImage", bannerImage);
    data.append("content", content);
    console.log(data);
    affilationpost(data)
      .then((res) => {
        console.log(res, ":::");
        // setSlug2(res)
        setLoader(false);
        toast.success("Successfully send");
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err?.response?.data?.errors);
        console.log(err);
      });
  };
  // ==================================

  // Affilation Get Api
  useEffect(() => {
    affilationget()
      .then((res) => {
        setAffilate(res?.data?.data);
        setForm((preval) => ({
          ...preval,
          content: res?.data?.data?.content,
        }));
        setContent(res?.data?.data?.content);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
// =========================
  return (
    <>
      {/* All stuff */}
      <section className="home-sec">
        <div className="container">
          <div className="home-head add-service-content">
            <h2>ADD AFFILATION CONTENT</h2>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="home-all">
                <div className="home-head">
                  <h3>Affilation Image</h3>
                  <img src={baseUrl + affilate?.bannerImage} width={"30%"} />
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    multiple={false}
                  />
                </div>
                <div className="home-head">
                  <h3>Section Content</h3>
                  <div className="form-group">
                    <TextEditor placeholder={'Enter'} content={content} setContent={setContent}/>
                  </div>
                </div>
                <div className="submit-btn">
                  <button onClick={Affilationdata}>
                    { loader ? <i className={"fa fa-spin fa-spinner"}></i> : 'Save Changes' }
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* =============================== */}
    </>
  );
}

export default Affilation;
