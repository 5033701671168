import React, { useState, useEffect } from "react";
import DashboardHeader from "../../components/DashboardHeader";

import all_orders from "../../constants/orders";
import { calculateRange, sliceData } from "../../utils/table-pagination";
import { Modal, Button } from "react-bootstrap";

import "../styles.css";
import DoneIcon from "../../assets/icons/done.svg";
import CancelIcon from "../../assets/icons/cancel.svg";
import RefundedIcon from "../../assets/icons/refunded.svg";
import { news, newsdelete, newsstore, newsupdate } from "../../network/Network";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import TextEditor from "../../components/texteditor/Texteditor";
import { EditorState } from "draft-js";

const News = () => {
  const dispatch = useDispatch();
  const [newstoress, setNewstoress] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [name, setName] = useState();
  const [title, setTitle] = useState();
  const [img, setimg] = useState();
  const [state, setState] = useState();
  const [image, setImage] = useState();
  const [short, setShort] = useState();
  const [des, setdes] = useState();
  const [loading, setLoading] = useState(false);
  const [spinLoad, setSpinLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [editData, setEditData] = useState([]);
  const [newFile, setNewFile] = useState();
  const [fileupload, setFileupload] = useState();
  const [selectedId, setSelectedId] = useState();
  const [selectedData, setSelectedData] = useState();
  const [description, setDescription] = useState();
  const [editstate, setEditState] = useState(false);
  const [editorState, setEditorState] = useState(() =>
  EditorState.createEmpty()
);
  const [imageSent, setImageSent] = useState(null);
  
  let limit = 10;

  // Image Handler
  const handleImageUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setNewFile(file);
      console.log("file", file);
      setFileupload(reader.result);
    };
    reader.readAsDataURL(file);
    // setUploadLoading(true);
  };
  // =====================================

  // News Post Api
  const newsblog = (e) => {
    e.preventDefault();
    setLoading(true);
    let data = new FormData();
    data.append("title", title);
    data.append("Image", newFile);
    data.append("description", des);
    data.append("short", short);
    console.log("chalo");
    newsstore(data)
      .then((res) => {
        console.log(res);
        setLoading(false);
        setIsOpenModal(false);
        setTitle(" ");
        setImage(" ");
        setdes(" ");
        setShort(" ");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
        setLoading(false);
        setTitle(" ");
        setImage(" ");
        setdes(" ");
        setShort(" ");
      });
  };
  // ===============================


  // News Get Api
  useEffect(() => {
    news()
      .then((res) => {
        setNewstoress(res?.data?.data);
        // setContent(res?.data?.data?.content);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // =====================
  return (
    <>
      {/* All stuff */}
      <div className="dashboard-content">
        {/* <DashboardHeader btnText="New Order" /> */}
        <div className="dashboard-content-container">
          <div className="blog-img">
            <label for="exampleFormControlTextarea1">News-image</label>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              multiple={false}
            />
          </div>
          <div className="blog-input">
            <label for="exampleFormControlTextarea1">News-heading</label>
            <input
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              type="text"
            />
            <div className="blog-btn">{/* <button>Edit</button> */}</div>
          </div>

          <div className="blog-input">
            <label for="exampleFormControlTextarea1">News-short</label>
            <input
              value={short}
              onChange={(e) => setShort(e.target.value)}
              type="text"
            />
            <div className="blog-btn">{/* <button>Edit</button> */}</div>
          </div>

          <div className="blog-text">
            <div class="form-group ">
              <label for="exampleFormControlTextarea1">News-description</label>
              {/* <textarea
                value={des}
                onChange={(e) => setdes(e.target.value)}
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
              ></textarea> */}
              <TextEditor
                placeholder={"Enter"}
                content={des}
                setContent={setdes}
              />
            </div>
            <div className="blog-btn">{/* <button>Edit</button> */}</div>
          </div>
          <div className="blog-date">
            {/* <label for="exampleFormControlTextarea1">News-date</label>
            <input type="text"></input> */}

            <div className="blog-btn">
              {/* <button>Edit</button> */}

              <button
                disabled={loading}
                onClick={(e) => {
                  newsblog(e);
                }}
              >
                {loader ? (
                  <i className={"fa fa-spin fa-spinner"}></i>
                ) : (
                  "Save Changes"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* ==================================== */}

        
    </>
  );
};

export default News;
