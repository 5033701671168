import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { baseUrl } from "../../network/ApiUrl";
import { servicesgetbyslug } from "../../network/Network";
import BusinessModal from "../../components/Buisnessmodal/Businessmodal";

function Business() {
  const [slug, setSlug] = useState("business-advisory-services");
  const [service, setService] = useState([]);
  const [singleService, setSingleService] = useState(null);
  const [edit, setEdit] = useState(false);
  const [heading, setHeading] = useState("");
  const [innerHeading, setinnerHeading] = useState("");
  const [short, setShort] = useState("");
  const [bannerImage, setBannerImage] = useState(null);
  const [newFile, setNewFile] = useState();
  const [fileupload, setFileupload] = useState();
  const [content, setContent] = useState("");

  useEffect(() => {
    let data = {
      slug: `/business-advisory-services`,
    };
    servicesgetbyslug(data)
      .then((res) => {
        console.log(res, "rrrr");
        setService(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [slug]);

  console.log( "service",service );
  return (
    <>
      <section className="business-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <table className="table-head">
                <tbody>
                  {service?.homeServices?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <h3 key={item?.id}>{index + 1} </h3>
                        </td>
                        <td>   <img 
                              className="img-fluid"
                              src={baseUrl + item?.bannerImage}
                            ></img></td>
                        <td>{item.heading}</td>
                        <td>
                          {" "}
                          <p
                            dangerouslySetInnerHTML={{ __html: item?.short }}
                          />
                        </td>
                        <td className="for-extra-content">
                          {" "}
                          <p 
                            dangerouslySetInnerHTML={{
                              __html: item?.content,
                            }}
                          />
                        </td>
                        <td>
                          <button
                              className="btn btn-info"
                              onClick={() => {
                                setSingleService(item);
                                setEdit(true);
                              }}
                          >
                            <i className="fa fa-pencil-square-o"></i>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>

      <BusinessModal
          singleService={singleService}
          show={edit}
          hide={() => setEdit(false)}
          setService={setSingleService}
      />
    </>
  );
}

export default Business;
