import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { careerdelete, careerget, careerupdate } from "../../network/Network";
import { Modal, Button } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { careerstore } from "../../network/Network";
import { type } from "@testing-library/user-event/dist/type";
import TextEditor from "../../components/texteditor/Texteditor";
function Career() {
  const dispatch = useDispatch();
  const [car, setCar] = useState([]);
  const [title, setTitle] = useState("");
  const [jobtype, setJobtype] = useState("");
  const [packages, setPackages] = useState("");
  const [des, setDes] = useState("");
  const [show, setShow] = useState("");
  const [editstate, setEditState] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState();
  const [spinLoad, setSpinLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [job_id, setJob_id] = useState("");
  const Token = useSelector((state) => state.AuthReducer.token);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [driverData, setDriverData] = useState([]);
  const [selectedId, setSelectedId] = useState();
  let limit = 10;

  // const UpdateJobHandler = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   let data = await new FormData();
  //   data.append("title", title);
  //   data.append("job_type", jobtype);
  //   data.append("package", packages);
  //   data.append("description", des);

  //   careerstore(data, Token)
  //     .then((res) => {
  //       console.log(res);
  //       toast.success(res?.data?.message);
  //       // dispatch(driverData(res?.data?.response?.data));
  //       // Navigate("/login/career");
  //       setLoading(false);
  //       setIsOpenModal(false);
  //       setTitle("");
  //       setJobtype("");
  //       setPackages(" ");
  //       setDes("");

  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       toast.error(err?.data?.message);
  //       setLoading(false);
  //       setTitle("");
  //       setJobtype("");
  //       setPackages(" ");
  //       setDes("");

  //     });
  // };
  console.log("Token", Token);
  const AddNewService = async (e) => {
    e.preventDefault();
    setLoading(true);

    let data = await new FormData();

    data.append("title", title);
    data.append("job_type", jobtype);
    data.append("package", packages);
    data.append("description", des);

    careerstore(data, Token)
      .then((res) => {
        console.log(res);
        // driverData.splice(
        //   res?.data?.response?.data.length + 1,
        //   0,
        //   res?.data?.response?.data
        // );
        toast.success(res?.data?.message);
        // dispatch(AllServices(res?.data?.response?.data));
        setLoading(false);
        setIsOpenModal(false);
        setTitle("");
        setJobtype("");
        setPackages(" ");
        setDes("");
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
        setLoading(false);
        setTitle("");
        setJobtype("");
        setPackages(" ");
        setDes("");
      });
  };
  useEffect(() => {
    setSpinLoad(true);
    careerget(currentPage, type)
      .then((res) => {
        console.log(res);
        setCar(res?.data?.data);
        // dispatch(setblogstoress(res?.data?.response?.data));
        setSpinLoad(false);
        const total = res?.data?.response?.data?.total;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
        setSpinLoad(false);
      });
  }, [limit, currentPage]);

  const DeleteNewHandler = (e, id) => {
    e.preventDefault();
    console.log("id", id);
    // setRemoveLoading(true);
    careerdelete(id)
      .then((res) => {
        console.log(res);
        setCar((prev) => {
          return [...prev.filter((val) => val?._id !== id)];
        });

        // toast.success(res?.data?.message);
        // teamMember?.splice(index, 1);
        // setteamMember(res?.data?.response?.data?.data);
        // setRemoveLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // setRemoveLoading(false);
      });
  };

  const Updatenewsmember = async (e) => {
    setLoading(true);
    e.preventDefault();
    setLoading(true);
    let data = await new FormData();
    data.append("title", title);
    data.append("job_type", jobtype);
    data.append("package", packages);
    data.append("description", des);
    careerupdate(data, selectedId, Token)
      .then((res) => {
        toast.success("Successfully send");
        setEditState(true);
        setLoading(false);

        setTitle("");
        setJobtype("");
        setPackages(" ");
        setDes("");
      })
      .catch((err) => {
        toast.error(err?.response?.data?.errors);
        console.log(err);
        setLoading(false);
        setTitle("");
        setJobtype("");
        setPackages(" ");
        setDes("");
        console.log(err);
      });
  };
  const EditStateHandler = (e, item) => {
    console.log("gvhvhgjgj", item);
    e.preventDefault();
    setTitle(item?.title);
    setJobtype(item?.job_type);
    setPackages(item?.package);
    setDes(item?.description);
  };

  return (
    <>
      <section className="Contact-sec">
        <div className="row">
          <div className="col-md-12">
            <div className="blog-btn add-new-modal">
              <Button onClick={handleShow}>Add New</Button>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="contact-name">
              <table className="contact-table career-table">
                <thead>
                  <th>Srno.</th>
                  <th>Job</th>
                  <th>Type</th>
                  <th>Salary</th>
                  <th>Description</th>
                  <th>Del</th>
                </thead>
                {car.map((data, index) => {
                  console.log(data);
                  return (
                    <tr value={data?.id} key={data?.id}>
                      <td>
                        <h3 key={data?._id}>{index + 1} </h3>
                      </td>
                      <td>
                        <h3>{data?.title}</h3>
                      </td>
                      <td>
                        <h3>{data?.job_type}</h3>
                      </td>
                      <td>
                        <h3>{data?.package}</h3>
                      </td>
                      <td className="description">
                        {/* <h3>{data?.description}</h3> */}
                        <h3
                          dangerouslySetInnerHTML={{
                            __html: data?.description,
                          }}
                        />
                      </td>
                      <td>
                        <i
                          class="fa fa-trash-o"
                          aria-hidden="true"
                          onClick={(e) => DeleteNewHandler(e, data?._id)}
                        ></i>

                        <i
                          class="fa fa-pencil-square-o"
                          aria-hidden="true"
                          data-toggle="modal"
                          data-target="#exampleModal"
                          onClick={(e) => {
                            EditStateHandler(e, data);
                            setSelectedId(data?._id);
                          }}
                        ></i>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </section>
      <div className="row">
        {car?.map((data, index) => {
          console.log(data);
          return (
            <div value={data?._id} key={data?._id} className="col-md-12">
              <Modal show={show} onHide={handleClose}>
                {/* <Modal.Header closeButton>
              <Modal.Title>Modal heading</Modal.Title>
            </Modal.Header> */}
                <Modal.Body>
                  <div className="team-img">
                    <div className="team-head">
                      <h3>Title</h3>
                      <input
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="team-designation">
                    <div className="team-head">
                      <h3>Jobtype</h3>
                      <input
                        type="text"
                        value={jobtype}
                        onChange={(e) => setJobtype(e.target.value)}
                      ></input>
                    </div>
                  </div>

                  <div className="team-designation">
                    <div className="team-head">
                      <h3>Package</h3>
                      <input
                        type="text"
                        value={packages}
                        onChange={(e) => setPackages(e.target.value)}
                      ></input>
                    </div>
                  </div>

                  <div className="team-description">
                    <div className="team-head">
                      <h3>Description</h3>
                      {/* <textarea
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        value={des}
                        onChange={(e) => setDes(e.target.value)}
                      ></textarea> */}
                      <TextEditor
                        placeholder={"Enter"}
                        content={des}
                        setContent={setDes}
                      />
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    disabled={loading}
                    onClick={(e) => AddNewService(e)}
                  >
                    {loading ? "Loading..." : "Post"}
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          );
        })}
      </div>

      {/* Career Modal   */}
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Edit Your Team Memeber Info
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {/* <div className="team-img">
                <div className="team-head">
                  <img
                    src={
                      baseUrl +
                      Newsstoress?.find((val) => val?._id === selectedId)?.image
                    }
                    width={"30%"}
                  />
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    multiple={false}
                  />
                </div>
              </div> */}
              {/* <div className="team-designation">
                <div className="team-head">
                  <h3>Name</h3>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  ></input>
                </div>
              </div> */}

              <div className="team-designation">
                <div className="team-head">
                  <h3>Title</h3>
                  <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  ></input>
                </div>
              </div>

              <div className="team-designation">
                <div className="team-head">
                  <h3>Short</h3>
                  <input
                    value={jobtype}
                    onChange={(e) => setJobtype(e.target.value)}
                    type="text"
                  />
                </div>
              </div>

              <div className="team-designation">
                <div className="team-head">
                  <h3>Short</h3>
                  <input
                    value={packages}
                    onChange={(e) => setPackages(e.target.value)}
                    type="text"
                  />
                </div>
              </div>

              <div className="team-description">
                <div className="team-head">
                  <h3>Description</h3>
                  {/* <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea> */}
                  <TextEditor
                    placeholder={"Enter"}
                    content={des}
                    setContent={setDes}
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={(e) => Updatenewsmember(e)}
                disabled={loading}
                data-dismiss="modal"
              >
                {loading ? "Loading.." : "Save changes"}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* ========================= */}
    </>
  );
}

export default Career;
