import React from 'react'
import { useEffect } from "react";
import { useState } from "react";
import { baseUrl } from "../../network/ApiUrl";
import { Link } from "react-router-dom";
import { servicesgetbyslug } from '../../network/Network';

function Lta() {
    const [slug, setSlug] = useState("lta");
    const [service, setService] = useState([]);
    // const [singleService, setSingleService] = useState(null);
    // const [edit, setEdit] = useState(false);
    useEffect(() => {
        let data = {
          slug: `/${slug}`,
        };
        servicesgetbyslug(data)
          .then((res) => {
            console.log(res?.data?.data, "rrrr");
            setService(res?.data?.data?.homeServices);
          })
          .catch((err) => {
            console.log(err);
          });
      }, [0]);  
  return (
    <>
         <section className="business-sec">
        <div className="container">
          <div className="row">
            <div className="home-head add-service-content text-center">
              <h2>LTA</h2>
            </div>

            <div className="col-md-12">
              <table className="table-head">
                <tbody>
                  {service?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <h3 key={item?.id}>{index + 1} </h3>
                        </td>
                        <td>
                          <img
                            className="img-fluid"
                            width={"25%"}
                            src={baseUrl + item?.bannerImage}
                          />
                        </td>
                        <td>{item.heading}</td>
                        <td>
                          {" "}
                          <p
                            dangerouslySetInnerHTML={{ __html: item?.short }}
                          />
                        </td>
                        {/*<td className="for-extra-content">*/}
                        {/*  {" "}*/}
                        {/*  <p*/}
                        {/*    dangerouslySetInnerHTML={{*/}
                        {/*      __html: item?.content,*/}
                        {/*    }}*/}
                        {/*  />*/}
                        {/*</td>*/}
                        <td>
                          <Link
                            className="btn btn-info"
                            to={`/lta/${item?.slug}`}
                          >
                            <i className="fa fa-pencil-square-o"></i>
                          </Link>
                          {/*<button*/}
                          {/*    className="btn btn-info"*/}
                          {/*    onClick={() => {*/}
                          {/*      setSingleService(item);*/}
                          {/*      setEdit(true);*/}
                          {/*    }}*/}
                          {/*>*/}
                          {/*  <i className="fa fa-pencil-square-o"></i>*/}
                          {/*</button>*/}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>

      {/*<Liquid*/}
      {/*    singleService={singleService}*/}
      {/*    show={edit}*/}
      {/*    hide={() => setEdit(false)}*/}
      {/*    setService={setSingleService}*/}
      {/*/>*/}
    </>
  )
}

export default Lta
