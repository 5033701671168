import React, { useEffect, useState } from "react";
import DashboardHeader from "../../components/DashboardHeader";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";

import all_orders from "../../constants/orders";
import { calculateRange, sliceData } from "../../utils/table-pagination";
import { Modal, Button } from "react-bootstrap";
import { SHOW, SINGLE_TEAM_MEMBER, TEAM_MEMBER } from "../../network/Endpoint";
import { ApiUrl, baseUrl } from "../../network/ApiUrl";

import "../styles.css";
import DoneIcon from "../../assets/icons/done.svg";
import CancelIcon from "../../assets/icons/cancel.svg";
import RefundedIcon from "../../assets/icons/refunded.svg";
import { toast } from "react-toastify";
import { PostTeams } from "../../network/Network";
import { useParams } from "react-router-dom";

const Teamdetail = () => {
  const param = useParams();
  let currentUrl = window.location.href.split("/");
  const [post, setPost] = useState(null);
  const [locationUrl, setLocationUrl] = useState(null);
  const [SingleMember, setSingleMember] = useState(null);
  const [siteSettings, setSiteSettings] = useState(null);

  console.log(param.id, "zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz");
  console.log(currentUrl[4]);
  const SingleSchoolApi = async () => {
    try {
      const { data } = await axios.get(
        `${ApiUrl + SINGLE_TEAM_MEMBER}${currentUrl[4]}`
      );
      setSingleMember(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    SingleSchoolApi();
    setLocationUrl(window.location.href);
  }, []);
  return (
    <>
      <section className="team-detail-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="team-detail-all">
                <img
                  className="img-fluid"
                  src={baseUrl + SingleMember?.image}
                />
                <h3>{SingleMember?.title}</h3>
                <h4>{SingleMember?.name}</h4>
                <p
                  dangerouslySetInnerHTML={{
                    __html: SingleMember?.description,
                  }}
                ></p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Teamdetail;
